import { v4 } from 'uuid';
import { log, debug } from '@/helpers';
import db from '@/database';
import { useDay } from '@/stores/day';
import { useUser } from '@/stores/user';
import { useFact } from '@/stores/fact';
import { useStat } from '@/stores/stat';
import { useHouse } from '@/stores/house';
import { useThing } from '@/stores/thing';
import { useAction } from '@/stores/action';
import { useExercise } from '@/stores/exercise';
import { useQuestion } from '@/stores/question';
import { useResource } from '@/stores/resource';
import { useFactHouse } from '@/stores/factHouse';
import { useActionDay } from '@/stores/actionDay';
import { useExerciseHouse } from '@/stores/exerciseHouse';
import { useResourceThing } from '@/stores/resourceThing';
import { useActionResource } from '@/stores/actionResource';
import { useQuestionHouse } from '@/stores/questionHouse';

let finished = false;
const databases = {
  action_resource: 'action_resource',
  resource_thing: 'resource_thing',
  action_day: 'action_day',
  resource: 'resources',
  action: 'actions',
  house: 'houses',
  thing: 'things',
  day: 'days',
  fact: 'facts',
  fact_house: 'fact_house',
  exercises: 'exercises',
  exercise_house: 'exercise_house',
  questions: 'questions',
  question_house: 'question_house',
  stat: 'stats',
};

const plugin = ({ store }) => {
  /**
   * Persist the payload to the local database.
   *
   * @return void
   */
  store.$onAction(async (action) => {
    // Only trigger when the action is named set, the state should be persisted
    // and we finished loading.
    if (
      (action.name !== 'set' &&
        action.name !== 'remove' &&
        action.name !== 'removeById') ||
      // eslint-disable-next-line no-prototype-builtins
      !databases.hasOwnProperty(action.store.$id) ||
      !finished
    ) {
      return;
    }

    if (action.name === 'remove' || action.name === 'removeById') {
      debug(`[Store] Persisting state to ${action.store.$id}.`);

      await db[databases[action.store.$id]].delete(
        action.name === 'remove' ? action.args[0].id : action.args[0],
      );
      return;
    }

    debug(`[Store] Persisting state to ${action.store.$id}.`, action.args[0]);

    await db[databases[action.store.$id]].put(
      Object.assign({}, action.args[0]),
    );
  });
};

const load = () => {
  // Retrieve the local state from the database and populate.
  const stores = {
    day: useDay(),
    house: useHouse(),
    thing: useThing(),
    action: useAction(),
    resource: useResource(),
    action_day: useActionDay(),
    action_resource: useActionResource(),
    resource_thing: useResourceThing(),
    fact: useFact(),
    fact_house: useFactHouse(),
    exercises: useExercise(),
    exercise_house: useExerciseHouse(),
    questions: useQuestion(),
    question_house: useQuestionHouse(),
    stat: useStat(),
  };

  return Promise.all(
    Object.keys(stores).map(async (database) => {
      const store = stores[database];

      log(`[Store] Loading persisted Pinia state from ${database}.`);

      return db[databases[database]]
        .filter((record) => {
          // eslint-disable-next-line no-prototype-builtins
          if (record.hasOwnProperty('deleted_at')) {
            return record.deleted_at === null;
          }

          return true;
        })
        .toArray()
        .then((result) => result.forEach((resource) => store.set(resource)));
    }),
  )
    .then(async () => {
      finished = true;
      debug('[Store] Finished loading the persisted state.');

      // Retrieve the current user if not yet fetched.
      const user = useUser();
      if (!user.$state.fetched) {
        await user.get();
      }

      // Ensure that the user has at least one house.
      const house = useHouse();
      if (house.$state.houses.length < 1) {
        house.set({
          id: v4(),
          user_id: user.$state.user.id,
          created_at: new Date(),
          updated_at: new Date(),
          deleted_at: null,
        });
      }
    })
    .catch((error) => console.error(error));
};

export { plugin, load, finished };
