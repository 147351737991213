<script setup>
import { ref } from 'vue';

import UpdateAvailable from '@/components/UpdateAvailable.vue';

const showSplash = ref(true);

setTimeout(() => {
  showSplash.value = false;
}, 4000);
</script>

<template>
  <div v-if="showSplash" class="splash-screen"></div>
  <router-view v-else />
  <UpdateAvailable />
</template>

<style lang="scss" scoped>
.splash-screen {
  background-image: url('/images/splashscreen.png');
  @apply h-screen w-screen bg-cover bg-center bg-no-repeat;
}
</style>
