import { defineStore } from 'pinia';
import { useHouse } from '@/stores/house';
import { fileToBase64 } from '../helpers';

export const useThing = defineStore('thing', {
  state: () => ({
    things: [],
  }),

  getters: {
    all: (state) =>
      state.things.filter((thing) => thing.house_id === useHouse().house?.id),
  },

  actions: {
    set(thing) {
      const index = this.things.findIndex((h) => h.id === thing.id);

      index > -1 ? (this.things[index] = thing) : this.things.push(thing);
    },
    removeById(id) {
      const index = this.things.findIndex((h) => h.id === id);

      if (index === -1) {
        return;
      }

      this.things[index] = undefined;
      this.things = this.things.filter((i) => i !== undefined);
    },
    remove(thing) {
      return this.removeById(thing.id);
    },
    async byHouse(houseId) {
      return new Promise((resolve) => {
        resolve(this.things.filter((thing) => thing.house_id === houseId));
      });
    },
    async setAmount(thingId, amount) {
      const thing = this.things.find((thing) => thing.id === thingId);

      // Convert the old images to base64 data.
      if (typeof thing.image !== 'string') {
        thing.image = await fileToBase64(thing.image);
      }

      thing.amount = amount;
      this.set(Object.assign({}, thing));
    },
  },
});
